import React from 'react';
import { Link } from 'gatsby';
import '../styles/pageNotFound.scss';

export default function PageNotFound() {
  return (
    <div className="pageNotFound">
      <div className="title">
        Page not found
      </div>
      <div className="link">
        <li>
          <Link to="/">Back to home</Link>
        </li>
      </div>
    </div>
  );
}
